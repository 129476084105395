import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import Circle from "@mui/icons-material/Circle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Error from "@mui/icons-material/Error";
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";
import PlcControl from "../PlcControl/PlcControl";

import allTrackViewsContext from "../../allTrackViewsContext";
import { useMap } from "react-map-gl";
import goToTrackViewContext from "../../goToTrackViewContext";
import tracks from "../../geojson/tracks.json";

import styles from "./Header.module.scss";
// import Image from 'next/image'
import rtsLogo from "./rtslogo.png";
import volvoLogo from "../../../public/volvologo.png";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { signIn, signOut, useSession } from "next-auth/react";
import { useDispatch, useSelector } from "react-redux";
import ServicesStatus, { ServiceStatus, Status } from "./ServicesStatus";
import { InfluxDB } from "@influxdata/influxdb-client";
import { DraggableIFrame } from "../DraggablePopup/DraggablePopup";
import { set as setStatus, updateServices } from "../../statusSlice";
import getDockerNodes from "./getDockerNodes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  calculateTimestamp,
  toggleControls as toggleReplayControls,
} from "../../redux/slices/replay";
import { update as updatePlcStatus } from "../../redux/slices/plcStatus";
import {
  CheckBoxOutlineBlank,
  Lightbulb,
  LightbulbOutlined,
  MapOutlined,
  Replay,
} from "@mui/icons-material";
import store from "../../reduxStore";
import { update as updateCategories } from "../../redux/slices/categories";
import axios from "axios";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function StatusButton(props) {
  const [style, setStyle] = useState(true);

  const style1 = { color: "#29f032", transform: "scale(1.5)" };
  const style2 = { color: "#0ec416", transform: "scale(1.5)" };
  let useStyle;
  if (style) {
    useStyle = style1;
  } else {
    useStyle = style2;
  }

  useInterval(() => {
    setStyle(!style);
  }, [1000]);

  const criticalServiceNames = [
    "vmonitor",
    "vmonitor-client",
    "tile38",
    "tile38follower",
    "geoevent",
    "geoevent2",
    "redis",
    "postgres",
    "caddy",
    "config-editor",
  ].map((item) => "vmonitor_" + item);

  /** @type{ServiceStatus[]} **/
  const dockerStatuses = props.dockerStatuses;
  const criticalServices = dockerStatuses?.filter((service) => {
    return criticalServiceNames.includes(service.service_name);
  });
  const dockerOkay = !criticalServices?.some((service) => service._value === 0);

  // TODO: Move status type (ok, warn, fail) and tooltip text into separate function

  /** @type{StatusSlice} **/
  const status = useSelector((state) => state.status);

  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(Date.now());

  useMemo(() => {
    console.log(status.websocket);
    if (status.websocket) {
      setStartTime(Date.now());
    } else {
      setEndTime(Date.now());
    }
    console.log(endTime - startTime);
  }, [status.websocket]);

  const warningServices = status.services.filter(
    (task) => task.status === Status.Warn
  );
  const failedServices = status.services.filter(
    (task) => task.status === Status.Fail
  );
  const failedCriticalServices = status.services
    .filter((task) => criticalServiceNames.includes(task.name))
    .filter((task) => task.status === Status.Fail);

  // Warn if any of the Docker nodes have failed
  const nodesWarn = status.nodes.down.length > 0;
  // Error if most of the Docker nodes have failed. (more failed than okay)
  const nodesFail = status.nodes.up.length <= status.nodes.down.length;
  const totalNodes = status.nodes.up.length + status.nodes.down.length;
  const nodeFetchError = status.nodes.down.some((node) => node === "error");

  // Gets the human readable error message for the status tooltip. This does not affect the overall status icon
  const tooltipLines = [];
  !dockerOkay ? tooltipLines.push("Error: Critical service failure") : null;
  !status.websocket ? tooltipLines.push("Error: Websocket failed") : null;
  !status.webui ? tooltipLines.push("Error: Web UI failed") : null;
  warningServices.length > 0 && !failedServices
    ? tooltipLines.push(
        `Warning: ${warningServices.length} services running at reduced capacity`
      )
    : null;
  failedServices.length > 0
    ? tooltipLines.push(`Error: ${failedServices.length} failed services`)
    : null;
  nodesWarn && !nodesFail && !nodeFetchError
    ? tooltipLines.push(
        `Warning: ${status.nodes.down.length}/${totalNodes} Docker nodes have failed`
      )
    : null;
  nodesFail && !nodeFetchError
    ? tooltipLines.push(
        `Error: ${status.nodes.down.length}/${totalNodes} Docker nodes have failed`
      )
    : null;
  nodeFetchError
    ? tooltipLines.push("Error: Failed to fetch Docker node statuses")
    : null;

  const tooltip = (
    <>
      {tooltipLines.map((line) => (
        <Typography key={line}>
          {line} <br />
        </Typography>
      ))}
    </>
  );

  // The logic here is in a strange order. This allows us to have "Warning" as the default state.
  if (
    props.status === "OK" &&
    dockerOkay &&
    status.websocket &&
    status.webui &&
    warningServices.length <= 0 &&
    failedServices.length <= 0 &&
    !nodesWarn &&
    !nodesFail
  ) {
    // console.log(!status.websocket, !status.webui, failedServices.length > 0, nodesFail, !dockerOkay, failedCriticalServices.length > 0)
    return (
      <Tooltip title={<Typography>No errors reported</Typography>}>
        <IconButton
          style={useStyle}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <RadioButtonChecked />
        </IconButton>
      </Tooltip>
    );
  } else if (
    !status.websocket ||
    !status.webui ||
    failedServices.length > 0 ||
    nodesFail ||
    !dockerOkay ||
    failedCriticalServices.length > 0
  ) {
    const websocketDowntime = !status.websocket ? (
      <Typography>Websocket down for {endTime - startTime} ms</Typography>
    ) : null;
    return (
      <Tooltip
        title={
          <>
            {tooltip}
            {websocketDowntime}
          </>
        }
      >
        <IconButton
          style={{ color: "#d32f2f", transform: "scale(1.5)" }}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <Error />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tooltip}>
        <IconButton
          style={useStyle}
          onClick={props.onClick}
          aria-label="status-button"
        >
          <ReportProblemIcon style={{ color: "#ff9100" }} />
        </IconButton>
      </Tooltip>
    );
  }
}

async function postTrackView(config, view) {
  const post = await axios.post(`${config.url.API}/updateTrackView`, view);
}

function MenuIconButton(props) {
  const { config } = props;
  const { current: map } = useMap();
  const permissions = useSelector((state) => state.permissions);
  const [allTrackViews, setAllTrackViews] = useContext(allTrackViewsContext);
  const [goToTrackView, setGoToTrackView] = useContext(goToTrackViewContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showReports, setShowReports] = useState(false);
  const dispatch = useAppDispatch();
  const isReplay = useAppSelector((state) => state.replay.isReplay);
  return (
    <div>
      <IconButton
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const temp = allTrackViews;
          temp[props.selectedTrack].longitude = props.selectedTrackView[0];
          temp[props.selectedTrack].latitude = props.selectedTrackView[1];
          temp[props.selectedTrack].zoom = props.selectedTrackView[2];
          temp[props.selectedTrack].bearing = props.selectedTrackView[3];
          // console.log(temp[props.selectedTrack].longitude)
          setAllTrackViews(temp);
          //TODO post data to backend
          let track = props.selectedTrack;
          let lat = props.selectedTrackView[1];
          let long = props.selectedTrackView[0];
          let zoom = props.selectedTrackView[2];
          let bearing = props.selectedTrackView[3];
          let view = {};
          view[track] = {
            longitude: long,
            latitude: lat,
            zoom: zoom,
            bearing: bearing,
            maxPitch: 0,
          };
          postTrackView(config, view).then();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setGoToTrackView(goToTrackView + 1);
          console.log(goToTrackView);
        }}
      >
        <HomeIcon></HomeIcon>
      </IconButton>

      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!isReplay && (
          <MenuLink onClick={handleClose} href={config.url.DASHBOARD}>
            Grafana
          </MenuLink>
        )}
        {permissions?.canModifyConfig && !isReplay && (
          <MenuLink onClick={handleClose} href={config.url.CONFIG}>
            Config
          </MenuLink>
        )}
        {(permissions?.canModifyAlarms || permissions?.canAccessAlarms) && (
          <MenuLink onClick={handleClose} href={"/alarms"}>
            Alarms
          </MenuLink>
        )}
        {permissions?.canAccessBookings && (
          <MenuLink onClick={handleClose} href={"/checkins"}>
            Check-ins
          </MenuLink>
        )}
        {
          <MenuLink onClick={handleClose} href={config.url.TRACK_STATUS}>
            Track Status
          </MenuLink>
        }
        {permissions?.canAccessReports && !isReplay && (
          <MenuLink onClick={handleClose} href={config.url.REPORTS}>
            Reports
          </MenuLink>
        )}

        <a href={"#"}>
          <MenuItem
            onClick={() => {
              props.toggleLightPanel(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <LightbulbOutlined style={{ transform: "scale(.75)" }} />
            </ListItemIcon>

            <ListItemText>Lights</ListItemText>
          </MenuItem>
        </a>
        <a href={"#"}>
          <MenuItem
            onClick={() => {
              props.handleGeofence(!props.geofenceOpen);
              handleClose();
            }}
          >
            <ListItemIcon>
              <MapOutlined style={{ transform: "scale(.75)" }} />
            </ListItemIcon>
            <ListItemText>Geofences</ListItemText>
          </MenuItem>
        </a>
        <a href={"#"}>
          {permissions?.canModifyConfig && config.isQA && (
            <MenuItem
              onClick={() => {
                dispatch(toggleReplayControls(true));
                handleClose();
              }}
            >
              <ListItemIcon>
                <Replay style={{ transform: "scale(.75)" }} />
              </ListItemIcon>
              <ListItemText>Open Replay Controls</ListItemText>
            </MenuItem>
          )}
        </a>
        <MenuItem>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <a
              href={"https://" + config.host + "/vmapi/versions/versions.json"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              <Typography variant={"subtitle2"}>
                vMonitor {config.version}
              </Typography>
            </a>
          </Grid>
        </MenuItem>
      </Menu>
      {showReports && (
        <DraggableIFrame
          url={config.url.REPORTS}
          title={"Reports"}
          open={showReports}
          onClose={() => setShowReports(false)}
          autosize={true}
        />
      )}
    </div>
  );
}

function MenuLink(props) {
  return (
    <a href={props.href} target={"_blank"} rel="noreferrer">
      <MenuItem onClick={props.onClick}>
        <ListItemIcon>
          <OpenInNewIcon style={{ transform: "scale(.75)" }} />
        </ListItemIcon>
        <ListItemText>{props.children}</ListItemText>
      </MenuItem>
    </a>
  );
}

function StatusList(props) {
  const statuses = props.statuses;
  const iconOK = <Circle style={{ color: "#0ec416" }} />;
  const iconFail = <Error color={"error"} />;
  const status = useSelector((state) => state.status);

  /**@type{LdapRoles}**/
  const permissions = useSelector((state) => state.permissions);

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem>
            <ListItemText primary="Service Status" />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>{status.websocket ? iconOK : iconFail}</ListItemIcon>
            <ListItemText primary="Websocket" />
          </ListItem>
          <ListItem>
            <ListItemIcon>{status.webui ? iconOK : iconFail}</ListItemIcon>
            <ListItemText primary="Web UI Server" />
          </ListItem>
        </List>
        {permissions.textid === "sysAdmin" && (
          <ServicesStatus statuses={statuses} dockerNodes={props.dockerNodes} />
        )}
      </nav>
    </Box>
  );
}

function Header({
  selectedTrack,
  selectedTrackView,
  handleModalOpen,
  modalOpen,
  menuItems,
  setOldMenuItem,
  servicesJSON,
  status,
  handleLeftClick,
  leftSelectedItem,
  toggleLightPanel,
  handleGeofence,
  geofenceOpen,
  config,
  permissions,
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();

  const [popOverOpen, setPopOverOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [servicesStatus, setServicesStatus] = React.useState(status);
  const [plcStatus, setPlcStatus] = React.useState("Inactive");
  const [plcControlOpen, setPlcControlOpen] = React.useState(false);

  const handlePopOverClick = () => {
    setPopOverOpen(!popOverOpen);
    setAnchorEl(divRef.current);
  };

  function handleClose() {
    setPopOverOpen(false);
  }

  const handleSelectMenu = (menu) => {
    setOpen(false);
  };

  useEffect(() => {
    setServicesStatus(status);
  }, [status]);

  const { data } = useSession({
    required: false,
    // onUnauthenticated() {
    //     signIn("LDAP").then(r => console.log(r))
    //     // The user is not authenticated, handle it here.
    // },
  });

  const dispatch = useDispatch();

  const fetchData = () => {
    const queryAPI = new InfluxDB({
      url: config.url.INFLUXDB,
      token: config.influxdb_token,
    }).getQueryApi("vmonitor");

    let rows = [];

    const observer = {
      next(row, tableMeta) {
        const o = tableMeta.toObject(row);
        // @ts-ignore
        rows.push(o);
        // console.log(o)
      },
      error(error) {
        console.error(error);
      },
      complete() {
        dispatch(updateServices(rows));
      },
    };

    // @ts-ignore
    queryAPI.queryRows(
      'from(bucket: "vmonitor")\n' +
        "  |> range(start: -1m)\n" +
        '  |> filter(fn: (r) => r["_measurement"] == "docker_swarm")\n' +
        '  |> group(columns: ["service_id", "_field"], mode:"by")\n' +
        "  |> last()\n" +
        '  |> sort(columns: ["service_name"])\n' +
        '  |> yield(name: "last")',
      observer
    );
    // queryAPI.queryRows('from(bucket: "vmonitor") |> range(start: -1m) |> filter(fn: (r) => r["_measurement"] == "docker_swarm") |> unique()', observer)
  };
  // fetchData()

  // const interval = setInterval(() => fetchData(),5000)
  // return clearInterval(interval)
  useInterval(() => fetchData(), 2500);

  const healthcheck = () => {
    fetch(window.location.origin + "/api/healthcheck")
      .then((data) => {
        dispatch(setStatus({ webui: data.status === 200 }));
      })
      .catch(() => dispatch(setStatus({ webui: false })));
  };
  useInterval(() => healthcheck(), 2000);

  const [dockerNodes, setDockerNodes] = useState({ up: [], down: [] });

  useEffect(() => {
    const interval = setInterval(() => {
      getDockerNodes(config.host, config.node).then((nodes) =>
        setDockerNodes(nodes)
      );
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(setStatus({ nodes: dockerNodes }));
  }, [dockerNodes]);

  useEffect(() => {
    if (config.plcDisabled !== "true") {
      connectPlcStatusEvent();
    }
  }, []);

  function connectPlcStatusEvent() {
    const plcStatusEvent = new EventSource(
      `${config.url.PLC}/plc-status-event`
    );
    plcStatusEvent.onmessage = (e) => {
      let json = JSON.parse(e.data);
      //plc status state
      if (typeof json === "string") {
        json = JSON.parse(json);
      }
      console.log(json);
      let plcState = json?.plcStatus ? json.plcStatus : "Inactive";
      setPlcStatus(plcState);
      store.dispatch(updatePlcStatus({ status: plcState }));
    };
    plcStatusEvent.onerror = (e) => {
      // console.log("PLC Status Event Error");
      plcStatusEvent.close();
      setTimeout(() => {
        // console.log("Reconnecting Menu Event")
        connectPlcStatusEvent();
      }, 1000);
    };
  }

  useEffect(() => {
    console.log("PLC Status: " + plcStatus);
  }, [plcStatus]);

  function getPlcColor() {
    if (plcStatus === "Active" || plcStatus === "Secure") {
      return "success";
    } else if (plcStatus === "Inactive") {
      return "warning";
    } else if (plcStatus === "Timeout") {
      return "error";
    } else {
      return "warning";
    }
  }

  const dockerStatuses = useSelector((state) => state.status.services);
  const replayConfig = useAppSelector((state) => state.replay);
  const isReplay = replayConfig.isReplay;

  return (
    <div className={styles.header}>
      <MenuIconButton
        config={config}
        selectedTrack={selectedTrack}
        selectedTrackView={selectedTrackView}
        toggleLightPanel={toggleLightPanel}
        handleGeofence={handleGeofence}
        geofenceOpen={geofenceOpen}
        permissions={permissions}
      />
      <ul className={styles.oldItemsMenu}>
        {Object.keys(menuItems).map((menuKey) => {
          const trackLevel = menuItems[menuKey]["track-level"]
            ? menuItems[menuKey]["track-level"]
            : [];
          let trackStatus = trackLevel.filter(
            (o) => o.name == "TrackStatus"
          )[0];
          trackStatus = trackStatus ? trackStatus.value : false;
          //console.log(menuKey + ": " + trackStatus);
          let allAlarmStatus = trackLevel.filter(
            (o) => o.name == "DisableAllTrackAlarms"
          )[0];
          allAlarmStatus = allAlarmStatus ? allAlarmStatus.value : false;
          // console.log(menuKey)

          const color = () => {
            if (trackStatus || (trackStatus && allAlarmStatus)) {
              return { background: "red", color: "white" };
            } else if (allAlarmStatus && !trackStatus) {
              return { background: "yellow", color: "#3d3a7a" };
            } else if (selectedTrack === menuKey) {
              // The active track has a white background
              return { background: "white", color: "#3d3a7a" };
            }
            return { background: "#d8d8ea", color: "#3d3a7a" };
          };

          const decoration = () => {
            if (selectedTrack === menuKey) {
              return { textDecoration: "underline" };
            }
          };

          return (
            <li title={tracks[menuKey] + " Alarm Control"} key={menuKey}>
              <Button
                variant="contained"
                sx={{
                  ...color(),
                  ...decoration(),
                  minWidth: 0,
                  paddingY: 0,
                  paddingX: 0.8,
                }}
                onClick={(e) => {
                  console.log(e);
                  handleLeftClick(menuItems[menuKey], menuKey);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (e.button === 2) {
                    console.log(permissions);
                    if (permissions.canModifyAlarms) {
                      setOldMenuItem(menuItems[menuKey], menuKey);
                    }
                  }
                }}
              >
                {menuKey}
              </Button>
            </li>
          );
        })}
      </ul>
      {isReplay ? (
        <ReplayInfo />
      ) : (
        <>
          <img
            alt={"volvo-logo"}
            src={"./volvologo.png"}
            width="192"
            height="108"
          />
          <img alt="rts-logo" src={"./rtslogo.png"} width="100" height="50" />
        </>
      )}
      {config.plcDisabled === "true" ? (
        ""
      ) : (
        <Button
          variant={"contained"}
          color={getPlcColor()}
          size={"small"}
          onClick={() => setPlcControlOpen(!plcControlOpen)}
        >
          PLC
        </Button>
      )}
      <Popover
        open={plcControlOpen}
        onClose={() => setPlcControlOpen(!plcControlOpen)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
      >
        <PlcControl
          config={config}
          setPlcControlOpen={setPlcControlOpen}
          plcStatus={plcStatus}
        />
      </Popover>
      <StatusButton
        status={servicesStatus}
        dockerStatuses={dockerStatuses}
        dockerNodes={dockerNodes}
        onClick={() => handlePopOverClick()}
      />
      <UserInfo username={data?.user?.name} config={config} />
      <div ref={divRef}>
        <Popover
          open={popOverOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
        >
          <StatusList
            json={servicesJSON}
            statuses={dockerStatuses}
            dockerNodes={dockerNodes}
            config={config}
          />
        </Popover>
      </div>
    </div>
  );
}

export default Header;

function UserInfo(props) {
  const { data } = useSession();

  /** @type {Config} */
  const config = props.config;

  const username = data?.user?.name;

  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const handleOpen = (e) => {
    setHover(true);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setHover(false);
    setAnchorEl(null);
  };
  const isReplay = useAppSelector((state) => state.replay.isReplay);

  useEffect(() => {
    if (username != null) {
      fetch("/api/getUserInfo").then((info) =>
        info.json().then((user) => setUserInfo(user))
      );
    }
  }, [username]);

  const bannerText = useMemo(() => {
    let banner = "";
    if (isReplay) {
      banner += "Replay Active";
    }
    if (isReplay && config.isQA) {
      banner += " - ";
    }
    if (config.isQA) {
      banner += "QA";
    }
    return banner;
  }, [config, isReplay]);

  if (username == null) {
    return (
      <Button
        variant="contained"
        onClick={() => signIn("LDAP").then((r) => console.log(r))}
      >
        Log In
      </Button>
    );
  }

  const open = Boolean(anchorEl);

  return (
    <div
      aria-owns={open ? "user-info-popover" : undefined}
      aria-haspopup="true"
    >
      <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {hover ? (
          <Button variant={"contained"} onClick={() => signOut()}>
            Log Out
          </Button>
        ) : (
          <>
            {bannerText !== "" && (
              <Typography
                color={"orange"}
                fontStyle={config.isReplay && "italic"}
              >
                {bannerText}
              </Typography>
            )}
            <Typography fontWeight={"bold"}>{username}</Typography>
            <Typography>{userInfo?.permissions?.name}</Typography>
          </>
        )}
      </div>
      <Popover
        id="user-info-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <div>
          {Object.keys(userInfo?.permissions ?? {})
            .filter((value) => value.includes("can"))
            .map((perm) => (
              <Typography key={perm} sx={{ paddingX: 1 }}>
                {perm}: {`${userInfo?.permissions[perm]}`}
              </Typography>
            ))}
          <br />
          {Object.keys(userInfo?.user ?? {}).map((field) => (
            <Typography key={field} sx={{ paddingX: 1 }}>
              {field}: {`${userInfo?.user[field]}`}
            </Typography>
          ))}
        </div>
      </Popover>
    </div>
  );
}

function ReplayInfo(props) {
  const replayConfig = useAppSelector((state) => state.replay);
  const [timestamp, setLocalTimestamp] = useState(
    calculateTimestamp(replayConfig)
  );
  const getTimestamp = useCallback(
    () => calculateTimestamp(replayConfig),
    [replayConfig]
  );
  useInterval(() => setLocalTimestamp(getTimestamp()), 10);
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Typography
        sx={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(toggleReplayControls(true));
        }}
      >
        {replayConfig.name}
      </Typography>
      <Typography>{timestamp.toISO()}</Typography>
    </Box>
  );
}
