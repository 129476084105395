import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Table,
  TableRow,
  TableCell,
  Typography,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import { Playback } from "../../../pages/api/replay/list";
import { DateTime, Interval } from "luxon";
import useSWR, { mutate } from "swr";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { set } from "../../redux/slices/replay";
import axios from "axios";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { clear } from "../Map/Marker/trackerSlice";
import { clear as clearWindows } from "../../trackerWindowsSlice";
import { ReplayInfo } from "./ReplayControls";
import LoadingButton from "@mui/lab/LoadingButton";

//@ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function ReplayListWrapper(props: {
  openControls: Function;
  defaultReplays: { replays: FetchedPlayback[] };
}) {
  const { data } = useSWR<{ replays: FetchedPlayback[] }>(
    "/api/replay/list",
    fetcher,
    {
      fallbackData: props.defaultReplays,
      refreshInterval: 10000,
    }
  );
  const replays = (data?.replays ?? [])
    .map((replay) => parseFetchedPlayback(replay))
    .sort((a, b) => a.createdAt.toUnixInteger() - b.createdAt.toUnixInteger());

  return (
    <Box sx={{ maxHeight: "60vh", overflowY: "scroll" }}>
      <ReplayList replays={replays} openControls={() => props.openControls()} />
    </Box>
  );
}

export function ReplayList(props: ReplayListProps) {
  const [selected, setSelected] = useState(-1);
  const [cachedExpandedInfo, setCachedExpandedInfo] = useState<{
    [key: string]: ReplayInfo;
  }>();
  const fetchInfo = (replay: Playback) => {
    axios
      .get("/api/replay/info", {
        params: { start: replay.start.toISO(), end: replay.end.toISO() },
      })
      .then((res) =>
        setCachedExpandedInfo((prevState) => ({
          ...prevState,
          [replay.name]: res.data,
        }))
      );
  };
  return (
    <Grid
      container
      justifyContent={"start"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      {props.replays.map((replay, i) => (
        <Box key={"replay-" + i} sx={{ marginY: 0.25, marginRight: 0.25 }}>
          <ReplayItem
            openControls={props.openControls}
            replay={replay}
            selected={i === selected}
            onClick={() =>
              setSelected((prevState) => (prevState === i ? -1 : i))
            }
            fetchInfo={fetchInfo}
            info={cachedExpandedInfo?.[replay.name]}
          />
          {i !== props.replays.length - 1 && <Divider variant={"middle"} />}
        </Box>
      ))}
    </Grid>
  );
}

function ReplayItem(props: {
  replay: Playback;
  selected: boolean;
  onClick: Function;
  openControls: Function;
  fetchInfo: (replay: Playback) => any;
  info?: ReplayInfo;
}) {
  const { replay } = props;
  const interval = Interval.fromDateTimes(replay.start, replay.end);
  console.log(interval);
  const duration = interval.toDuration().toFormat("mm") + " mins";
  const summary = `${duration} | ${replay.start.toLocaleString({ dateStyle: "medium" })} from ${replay.start.toLocaleString({ timeStyle: "short", hourCycle: "h23" })} to ${replay.end.toLocaleString({ timeStyle: "short", hourCycle: "h23" })}`;

  const dispatch = useAppDispatch();
  const loadReplay = () => {
    dispatch(
      set({
        running: false,
        startDate: replay.start.toMillis(),
        endDate: replay.end.toMillis(),
        isReplay: true,
        lastTimestamp: replay.start.toMillis(),
        name: replay.name,
        started: Date.now(),
        controlsOpen: true,
        firstLoad: true,
        isFromRemote: false,
        currentIMEIs: [],
      })
    );
    dispatch(clearWindows());
    dispatch(clear());
    axios
      .post("/api/replay/sendCommand", {
        command: "load",
        name: replay.name,
        start: replay.start.toUTC().toMillis(),
        end: replay.end.toUTC().toMillis(),
      })
      .catch(console.error);
    // fetch(`/api/replay/trackerSender/start?start=${replay.start.toISO({includeOffset: false})}&end=${replay.end.toISO({includeOffset: false})}`).catch(console.error)
    props.openControls();
  };
  const [editing, setEditing] = useState(false);
  const [notes, setNotes] = useState(props.replay.notes);

  const saveNotes = useCallback(() => {
    updateReplayNotes(props.replay.name, notes)
      .then((res) => {
        setEditing(false);
        if (!!res.data?.replays) {
          mutate("/api/replay/list", res.data).catch(console.error);
        }
      })
      .catch(console.error);
  }, [notes]);

  const deleteReplay = () => {
    const shouldDelete = confirm(`Delete "${props.replay.name}"?`);
    if (shouldDelete) {
      axios
        .post("/api/replay/delete", replay)
        .then((res) => {
          mutate("/api/replay/list", res.data).catch(console.error);
        })
        .catch(console.error);
    }
  };

  // const {data: replayInfo} = useSWR<ReplayInfo>(`/api/replay/info?start=${props.replay.start.toISO()}&end=${props.replay.end.toISO()}`, fetcher)

  const permissions = useAppSelector((state) => state.permissions);

  const [showCounts, setShowCounts] = useState(false);
  const loadExpandedInfo = useCallback(() => {
    if (!props.info) {
      props.fetchInfo(props.replay);
    }
    setShowCounts((old) => !old);
  }, [showCounts, props.replay, props.info]);

  useEffect(() => {
    if (!props.selected) {
      setShowCounts(false);
    }
  }, [props.selected]);

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Grid container justifyContent={"space-between"} alignItems={"start"}>
            <Grid item flexGrow={1}>
              <CardActionArea onClick={() => props.onClick()}>
                <Typography variant={"body1"}>{replay.name}</Typography>
              </CardActionArea>
            </Grid>
            <Grid item sx={{ display: props.selected ? "" : "none" }}>
              <IconButton
                onClick={() => (editing ? deleteReplay() : props.onClick())}
                sx={{ padding: 0, marginTop: -0.25 }}
              >
                {editing ? (
                  <Delete color={"error"} />
                ) : (
                  <CloseIcon opacity={0} color={"error"} />
                )}
              </IconButton>
            </Grid>
          </Grid>

          {
            <Typography
              variant={"caption"}
              sx={{ display: !props.selected ? "inherit" : "none" }}
            >
              {summary}
            </Typography>
          }

          <Collapse in={props.selected}>
            <Table size={"small"} sx={{ marginTop: 1, marginBottom: 1 }}>
              <TableRow>
                <TableCell>
                  <Typography variant={"body2"}>Created At</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"body2"}>
                    {replay.createdAt.toLocaleString({
                      dateStyle: "medium",
                      timeStyle: "short",
                      hourCycle: "h23",
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant={"body2"}>Created By</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"body2"}>{replay.createdBy}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant={"body2"}>Start</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"body2"}>
                    {replay.start.toLocaleString({
                      dateStyle: "medium",
                      timeStyle: "short",
                      hourCycle: "h23",
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant={"body2"}>End</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"body2"}>
                    {replay.end.toLocaleString({
                      dateStyle: "medium",
                      timeStyle: "short",
                      hourCycle: "h23",
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
              {permissions.textid === "sysAdmin" && (
                <TableRow>
                  <TableCell>
                    <Typography variant={"body2"}>ID</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={"body2"}>
                      {replay.directory}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {
                <TableRow
                  sx={{
                    cursor: "pointer",
                    borderBottom: showCounts ? "2px black solid" : "inherit",
                  }}
                  onClick={() => loadExpandedInfo()}
                >
                  <TableCell sx={{ color: "primary" }}>
                    <LoadingButton
                      size={"small"}
                      loading={showCounts && !props.info}
                    >
                      {showCounts ? "Hide Counts" : "Show Counts"}
                    </LoadingButton>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              }
              {showCounts &&
                props.info?.counts &&
                Object.entries(props.info?.counts).map((entry) => (
                  <TableRow key={entry[0]}>
                    <TableCell>{`${entry[0]}`}</TableCell>
                    <TableCell>{`${entry[1]}`}</TableCell>
                  </TableRow>
                ))}
            </Table>
            <Typography variant={"body2"} fontWeight={"bold"}>
              Notes:
            </Typography>
            {editing ? (
              <TextField
                sx={{ marginBottom: 0.5 }}
                name={"Notes"}
                size={"small"}
                multiline
                maxRows={3}
                minRows={1}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            ) : (
              <Typography variant={"body2"} sx={{ maxWidth: "13vw" }} paragraph>
                {replay.notes}
              </Typography>
            )}
            <Grid
              container
              justifyContent={"space-between"}
              sx={{ marginBottom: -1 }}
            >
              {editing ? (
                <Button
                  variant={"outlined"}
                  onClick={() => saveNotes()}
                  color={"success"}
                >
                  Save
                </Button>
              ) : (
                <Button variant={"outlined"} onClick={() => loadReplay()}>
                  Load
                </Button>
              )}
              <Button
                variant={"outlined"}
                onClick={() => setEditing((prev) => !prev)}
                color={editing ? "error" : "primary"}
              >
                {editing ? "Cancel" : "Edit"}
              </Button>
            </Grid>
          </Collapse>
        </CardContent>
        {/*<ListItemButton onClick={() => props.onClick()}>*/}
        {/*    <ListItemText primary={replay.name} secondary={summary} />*/}
        {/*</ListItemButton>*/}
      </Card>
    </>
  );
}

interface ReplayListProps {
  replays: Playback[];
  openControls: Function;
}

async function updateReplayNotes(name: string, notes: string) {
  return await axios.post("/api/replay/updateNotes", {
    name,
    notes,
  });
}

function parseFetchedPlayback(playback: FetchedPlayback): Playback {
  const createdAt =
    typeof playback.createdAt === "number"
      ? DateTime.fromMillis(playback.createdAt)
      : DateTime.fromISO(playback.createdAt);
  const start =
    typeof playback.start === "number"
      ? DateTime.fromMillis(playback.start)
      : DateTime.fromISO(playback.start);
  const end =
    typeof playback.end === "number"
      ? DateTime.fromMillis(playback.end)
      : DateTime.fromISO(playback.end);
  return {
    ...playback,
    createdAt: createdAt,
    start: start,
    end: end,
  };
}

export interface FetchedPlayback {
  name: string;
  start: string | number;
  end: string | number;
  createdAt: string | number;
  createdBy: string;
  notes: string;
}
