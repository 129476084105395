export default async function getDockerNodes(host, node) {
  // TODO: make dynamic
  try {
    const request = await fetch(`https://${host}/prometheus/api/v1/targets`);
    if (request.status !== 200) {
      throw `Expected Status 200, got ${request.status}`;
    }
    const data: PrometheusTargets = await request.json();

    const nodes = data.data.activeTargets.filter(
      (target) => target.labels.job === "docker"
    );
    const keepalivedNodes = data.data.activeTargets.filter(
      (target) => target.labels.job === "keepalived"
    );
    let upNodes = nodes
      .filter((node) => node.health === "up")
      .map((node) => node.labels.docker_node ?? "unknown");
    let downNodes = nodes
      .filter((node) => node.health === "down")
      .map((node) => node.labels.docker_node ?? "unknown");

    if (keepalivedNodes.length > 0) {
      upNodes = keepalivedNodes
        .filter((node) => node.health === "up")
        .map((node) => node.labels.instance.replace(":9165", "") ?? "unknown");
      downNodes = keepalivedNodes
        .filter((node) => node.health === "down")
        .map((node) => node.labels.instance.replace(":9165", "") ?? "unknown");
      for (let i = 0; i < upNodes.length; i++) {
        if (upNodes[i] === node) {
          upNodes[i] = upNodes[i] + " (Active)";
        }
      }
      for (let i = 0; i < downNodes.length; i++) {
        if (downNodes[i] === node) {
          downNodes[i] = downNodes[i] + " (Active)";
        }
      }
    }

    console.log(upNodes);
    console.log(downNodes);

    return {
      up: upNodes.sort(),
      down: downNodes.sort(),
    };
  } catch (e) {
    console.error(e);
    return {
      up: [],
      down: ["error", "error", "error"],
    };
  }
}

export interface PrometheusTargets {
  status: string;
  data: Data;
}

export interface Data {
  activeTargets: ActiveTarget[];
  droppedTargets: DroppedTarget[];
}

export interface ActiveTarget {
  discoveredLabels: DiscoveredLabels;
  labels: Labels;
  scrapePool: string;
  scrapeUrl: string;
  globalUrl: string;
  lastError: string;
  lastScrape: Date;
  lastScrapeDuration: number;
  health: Health;
  scrapeInterval: ScrapeInterval;
  scrapeTimeout: ScrapeTimeout;
}

export interface DiscoveredLabels {
  __address__: string;
  __metrics_path__: MetricsPath;
  __scheme__: Scheme;
  __scrape_interval__: ScrapeInterval;
  __scrape_timeout__: ScrapeTimeout;
  job: string;
  __meta_dockerswarm_node_address?: string;
  __meta_dockerswarm_node_availability?: string;
  __meta_dockerswarm_node_engine_version?: string;
  __meta_dockerswarm_node_hostname?: string;
  __meta_dockerswarm_node_id?: string;
  __meta_dockerswarm_node_manager_address?: string;
  __meta_dockerswarm_node_manager_leader?: string;
  __meta_dockerswarm_node_manager_reachability?: string;
  __meta_dockerswarm_node_platform_architecture?: string;
  __meta_dockerswarm_node_platform_os?: string;
  __meta_dockerswarm_node_role?: string;
  __meta_dockerswarm_node_status?: string;
}

export enum MetricsPath {
  Metrics = "/metrics",
  MetricsCaddy = "/metrics/caddy",
  PrometheusMetrics = "/prometheus/metrics",
}

export enum Scheme {
  HTTP = "http",
}

export enum ScrapeInterval {
  The15S = "15s",
}

export enum ScrapeTimeout {
  The10S = "10s",
}

export enum Health {
  Down = "down",
  Up = "up",
}

export interface Labels {
  instance: string;
  job: string;
  docker_node?: string;
}

export interface DroppedTarget {
  discoveredLabels: { [key: string]: string };
}
