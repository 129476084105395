import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import { useAppSelector } from "../../hooks/reduxHooks";

export default function PlcControl(props) {
  const { config, setPlcControlOpen, plcStatus } = props;

  const isReplay = useAppSelector((state) => state.replay.isReplay);
  const [disabled, setDisabled] = useState(isReplay);

  async function sendPLC(command) {
    const url = `${config.url.PLC}/` + command;
    console.log("PLC Barrier Command: " + url);
    const response = await axios.get(url);
    setPlcControlOpen(false);
  }

  function getStatus() {
    if (plcStatus === "Active" || plcStatus === "Secure") {
      return "Control";
    } else if (plcStatus === "Inactive") {
      return "Released";
    } else {
      return "PLC Error";
    }
  }

  return (
    <Box
      sx={{
        width: "95%",
        maxWidth: 500,
        bgcolor: "background.paper",
        padding: "5px",
      }}
    >
      <Stack spacing={1}>
        <ListItemText>Status: {getStatus()}</ListItemText>
        <Divider />
        <Button
          variant={"contained"}
          color={"error"}
          disabled={disabled}
          size={"small"}
          onClick={() => sendPLC("Control")}
        >
          Take Control
        </Button>
        <Button
          variant={"contained"}
          color={"warning"}
          disabled={disabled}
          size={"small"}
          onClick={() => sendPLC("Release")}
        >
          Release Control
        </Button>
        <Button
          variant={"outlined"}
          size={"small"}
          color={"error"}
          onClick={() => {
            if (window.confirm("Reset PLC Connection?")) {
              sendPLC("Restart");
            }
          }}
        >
          Reset PLC
        </Button>
      </Stack>
    </Box>
  );
}
